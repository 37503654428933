@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Peralta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap');

::-webkit-scrollbar {
    width: 1em;
}


::-webkit-scrollbar-track {
    background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
    background: #757575 ;
}

@supports (scrollbar-color: red blue) {
    * {
        scrollbar-color: #9e9e9e #2e2e2e ;
    }
}

::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
}